import React, { useEffect, lazy, Suspense } from 'react';
import { Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Alert from 'react-s-alert';
import { parse } from 'query-string';
import { ThemeProvider } from 'styled-components/macro';
import useOnboardingBanner from 'utils/hooks/useOnboardingBanner';
import { theme } from 'styles/theme';
import Cookies from 'js-cookie';
import Tracking from 'components/Tracking';
import { GettingStarted } from 'components/Onboarding/PreConversion/Interstitial/sections/GettingStarted';
import { PageWrapper } from 'App.styled';
import { useKetchScript } from 'utils/ketch/script';
import { isReleaseOrProduction } from 'utils/common';
import { Spinner } from '@cerebral-inc/design-system';
import { sign_out } from './actions/user_auth_action';
import { Logout, Signup as UniversalLoginSignUp, MobileLogin } from './components/UniversalLogin';
import TimerModal, { showTimerModal } from './components/OutstandingTasksModal/TimerModal';
import ErrorBoundary, { ErrorPage } from './ErrorBoundary';
import 'react-s-alert/dist/s-alert-default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/cerebral.scss';
import ApiTester from './components/ApiTester';
import initRum from './utils/datadog-rum';
import segment from './utils/segment';
import Banner from './components/Banner';
import { triggerEventKameleoon } from './utils/kameleoon/utils';
import strings from './localization';
import { writeClickIdsToCookie } from './components/Onboarding/helpers';
import { extractMSClickId } from './utils/hooks/useAnalyticsPlatform';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER_URL;
// Jacob's right
// Add custom header to sent for track segment user on server
const segmentAID = localStorage.getItem('ajs_anonymous_id');

if (segmentAID) {
  axios.defaults.headers.common['X-AnonymousId'] = segmentAID.replace(/['"]+/g, '');
}

const Patient = lazy(() => import('./containers/patient'));
const DirectRoutes = lazy(() => import('./components/DirectRoutes'));
const MemberSignIn = lazy(() => import('./components/SignIn'));
const UniversalLogin = lazy(() => import('./components/UniversalLogin'));
const SignUp = lazy(() => import('./components/sign_up/sign_up_segment_container'));
const PreSignUpManager = lazy(() => import('components/Onboarding/PreSignupFlow/QuestionManager'));

const App = props => {
  const { isSignedIn } = props;
  const history = useHistory();
  const { pathname, search } = useLocation();
  const country = 'us';
  const UrlParams = parse(search);
  const bannerProps = useOnboardingBanner();
  const appsflyerObj = segment.urlParamsToContext(UrlParams);
  const isFromMobileApp = sessionStorage.getItem('fromMobileUser') !== null;

  useEffect(() => {
    initRum();

    // Check utm_content in URL parameter to set into localStorage
    const params = new URLSearchParams(window.location.search);
    if (params.get('anonymous_id')) {
      sessionStorage.setItem('anonymous_id', params.get('anonymous_id'));
    }
    if (params.get('utm_content')) {
      window.localStorage.setItem('utm_content', params.get('utm_content'));
      triggerEventKameleoon(params.get('utm_content'));
    }
    if (params.get('datadog_synthetic_test')) {
      window.localStorage.setItem('datadog_synthetic_test', params.get('datadog_synthetic_test'));
    }
    if (params.get('enable_segment_track') && !Cookies.get('enable_segment_track')) {
      Cookies.set('enable_segment_track', true, { expires: 1 });
    }
    // Check onboarding flow in URL parameter to set into Cookies
    if (params.get('ob_flow')) {
      sessionStorage.setItem('ob_flow', params.get('ob_flow'));
    }
    // Guest Appointment Id
    if (params.get('guest_appointment_id')) {
      sessionStorage.setItem('guest_appointment_id', params.get('guest_appointment_id'));
    }
    extractMSClickId(params);
  }, []);

  useEffect(() => {
    writeClickIdsToCookie();
  }, []);

  useKetchScript();

  useEffect(() => {
    segment.page(pathname, undefined, appsflyerObj);
    window.scrollTo(0, 0);
  }, [pathname]);

  strings.setLanguage(country);

  const onLogout = () => {
    history.push('/logout');
  };

  return (
    <ThemeProvider theme={theme}>
      <Tracking />
      <Banner {...bannerProps} />

      <div className="App d-flex justify-content-center">
        <PageWrapper>
          {isSignedIn && !isFromMobileApp && showTimerModal(pathname) && <TimerModal onLogout={onLogout} />}
          <ErrorBoundary>
            <Suspense fallback={<Spinner variant="screen" hideScreenOverlay />}>
              <Switch>
                <Route
                  path="/patient/sign-up"
                  render={() => {
                    return <Redirect to={`/signup${search}`} />;
                  }}
                />
                <Route
                  path="/patient"
                  render={() => {
                    return <Patient />;
                  }}
                />
                {isReleaseOrProduction() ? (
                  <Route path="/member_sign_in" component={UniversalLogin} />
                ) : (
                  <Route path="/member_sign_in" component={MemberSignIn} />
                )}
                <Route path="/logout" component={Logout} />
                <Route path="/mobile-login" component={MobileLogin} />
                <Route
                  path="/signup"
                  render={() => {
                    if (isReleaseOrProduction()) {
                      return <UniversalLoginSignUp />;
                    }
                    return <SignUp />;
                  }}
                />
                <Route path="/appointment" component={DirectRoutes} />

                <Route path="/pre-signup/:questionName?" component={PreSignUpManager} />

                <Route path="/getting-started" component={GettingStarted} />
                <Route path="/error" component={ErrorPage} />
                {isReleaseOrProduction() ? <Route component={UniversalLogin} /> : <Route component={MemberSignIn} />}
              </Switch>
            </Suspense>
            <Alert stack={{ limit: 3 }} />
          </ErrorBoundary>
          {process.env.REACT_APP_INSTALL_API_TESTER !== 'featureFlags.auth_userlogin_auth0' && <ApiTester />}
        </PageWrapper>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  isSignedIn: state.global_reducer.current_user.is_signedIn
});

const mapDispatchToProps = {
  logout: sign_out
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
